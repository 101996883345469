import { useIntersectionObserver } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { PageLogo } from './PageLogo';
import { TopNav } from './TopNav';
import { Link, useParams } from 'react-router-dom';

export function PageHeader() {
  // Get routere resultId param
  const { resultId } = useParams<{ resultId: string }>();
  const [topAnchorRef, topAnchorEntry] =
    useIntersectionObserver<HTMLDivElement>({
      threshold: 0,
      root: null,
      rootMargin: '0px',
    });

  return (
    <div className="bg-app-body text-app-primary-foreground">
      <div ref={topAnchorRef} />
      <header className="relative pb-5 pt-8 lg:pb-8">
        <div
          className={clsx(
            'fixed inset-x-0 top-0 z-50 transition-all duration-500',
            {
              'bg-white shadow-[0px_-10px_24px_rgba(0,0,0,0.1)]':
                (topAnchorEntry?.boundingClientRect.y ?? 0) < 0,
            }
          )}
          data-header
        >
          <div className="container flex items-center justify-between lg:py-2 xl:pb-6 xl:pt-10">
            <div>
              <Link to={resultId ? `/services/${resultId}` : '/'}>
                <PageLogo
                  className="object-left lg:h-[60px] lg:w-[255px]"
                  variant="dark"
                />
              </Link>
            </div>
            <TopNav />
          </div>
        </div>

        <div className="sm:pb-5" />
      </header>
    </div>
  );
}
