import type { ITenant } from '@/types';
import logo from '@/assets/onesource-logo.svg';
import logoDark from '@/assets/onesource-logo-dark.svg';

export const OSC_TENANT: ITenant = {
  isPartner: false,
  name: 'OneSource Solutions',
  logo,
  logoDark,
  email: 'hello@osconnects.com',
  phone: '877-274-8632',
  facebookUrl: 'https://www.facebook.com/OneSourceSolutionsCorp',
  linkedInUrl:
    'https://www.linkedin.com/company/onesource-solutions-home-connections/',
};
