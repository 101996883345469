import { Link, useParams } from 'react-router-dom';
import { PageLogo } from './PageLogo';
import { TopNav } from './TopNav';

export function PartnerPageHeader() {
  const { resultId } = useParams<{ resultId: string }>();

  return (
    <div className="bg-app-body">
      <header className="relative">
        <div className="container flex items-center justify-between py-2 lg:py-5">
          <div>
            <Link to={resultId ? `/services/${resultId}` : '/'}>
              <PageLogo
                className="object-left lg:h-[60px] lg:w-[255px]"
                variant="dark"
              />
            </Link>
          </div>

          <TopNav isPartner />
        </div>
      </header>
    </div>
  );
}
