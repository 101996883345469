import { useTenant } from '@/hooks/useTenant';

import { MarketingSiteLink } from '../MarketingSiteLink';
export function PageFooter() {
  const tenant = useTenant();

  return (
    <>
      <footer className="bg-app-primary py-10 text-base text-app-primary-foreground">
        <div className="container grid gap-10 lg:grid-cols-3">
          <div className="flex flex-col gap-6">
            {!!tenant.logo && (
              <img src={tenant.logo} alt={tenant.name} width="255" />
            )}

            <p>
              &copy; {new Date().getFullYear()} {tenant.name}. All rights
              reserved.
            </p>
          </div>

          <div className="grid gap-10 lg:col-span-2 lg:grid-cols-3">
            <div>
              <h3 className="mb-4 text-lg font-bold">Contact Us</h3>

              {!!tenant.email && (
                <p className="mb-4">
                  <a
                    href={`mailto:${tenant.email}`}
                    className="text-base font-bold text-app-primary-foreground hover:underline"
                  >
                    {tenant.email}
                  </a>
                </p>
              )}

              {!!tenant.phone && (
                <p>
                  <a
                    href={`tel:${tenant.phone.replace(/\D/g, '')}`}
                    className="text-base font-bold text-app-primary-foreground hover:underline"
                  >
                    {tenant.phone}
                  </a>
                </p>
              )}
            </div>

            <div>
              <h3 className="mb-4 text-lg font-bold">Resources</h3>

              <p className="mb-4">
                <MarketingSiteLink
                  href="/terms-of-use"
                  className="text-app-primary-foreground hover:underline"
                >
                  Terms of Use
                </MarketingSiteLink>
              </p>
              <p>
                <MarketingSiteLink
                  href="/privacy-notice"
                  className="text-app-primary-foreground hover:underline"
                >
                  Privacy Policy
                </MarketingSiteLink>
              </p>
            </div>

            {!!tenant.facebookUrl ||
              (!!tenant.linkedInUrl && (
                <div>
                  <h3 className="mb-4 text-lg font-bold">Follow Us</h3>
                  <ul className="flex gap-4">
                    {!!tenant.facebookUrl && (
                      <li>
                        <a
                          href={tenant.facebookUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg height="40" width="35" viewBox="0 0 448 512">
                            <title>Follow us On Facebook</title>
                            <path
                              d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </li>
                    )}
                    {!!tenant.linkedInUrl && (
                      <li>
                        <a
                          href={tenant.linkedInUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="40"
                            width="35"
                            viewBox="0 0 448 512"
                            fill="currentColor"
                          >
                            <title>Follow us On LinkedIn</title>
                            <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                          </svg>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </footer>
    </>
  );
}
