import { useRootLoaderData } from '@/hooks/useRootLoaderData';
import { useSubmit } from 'react-router-dom';
import { Button } from './Button';

type Props = {
  closeNav: () => void;
};

export const UserNavigation = ({ closeNav }: Props) => {
  const { currentUser } = useRootLoaderData();

  const submit = useSubmit();

  const handleLogout = () => {
    submit({}, { method: 'DELETE', action: '/auth/logout' });
  };

  return (
    <div className="group relative mx-auto mt-10  flex max-w-xs flex-col justify-center pt-10 xl:ml-6 xl:mt-0 xl:p-0">
      {!currentUser && (
        <Button
          to="/login"
          variant="tertiary"
          size="sm"
          onClick={() => closeNav()}
        >
          Login
        </Button>
      )}
      {currentUser && (
        <>
          <Button
            to="/account"
            variant="tertiary"
            size="sm"
            onClick={() => closeNav()}
          >
            My Account
          </Button>
          <div className="mt-4 xl:absolute xl:top-full xl:mt-4 xl:hidden xl:w-full xl:rounded-lg xl:bg-white xl:shadow xl:group-focus-within:block  xl:group-hover:block">
            <div className="absolute bottom-full hidden h-4 w-full before:absolute before:bottom-0 before:left-1/2 before:size-0 before:-translate-x-1/2 before:border-x-[6px] before:border-b-8 before:border-transparent before:border-b-white xl:block" />
            <div className="xl:p-2">
              <button
                type="button"
                onClick={handleLogout}
                className="w-full rounded-lg p-2 text-sm uppercase tracking-wider text-app-primary xl:hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
